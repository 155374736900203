export default [
    {
        path: "/project_check_list",
        name: "project_check_list",
        meta: { title: "项目待审核列表" },
        component: () => import("@/views/project/project_check_list"),
    },
    {
        path: "/project_list",
        name: "project_list",
        meta: { title: "项目列表" },
        component: () => import("@/views/project/project_list"),
    },
    {
        path: "/cert_list",
        name: "cert_list",
        meta: { title: "认证待审核列表" },
        component: () => import("@/views/project/cert_list"),
    },
    {
        path: "/project_list_add",
        name: "project_list_add",
        meta: { title: "项目列表" },
        component: () => import("@/views/project/project_list_add"),
    },
    {
        path: "/project_claim_list",
        name: "project_claim_list",
        meta: { title: "项目认领审核列表" },
        component: () => import("@/views/project/project_claim_list"),
    },
    {
        path: "/finance_user_list",
        name: "finance_user_list",
        meta: { title: "认证会员列表" },
        component: () => import("@/views/project/finance_user_list"),
    },
];
