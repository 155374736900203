import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import "./assets/css/global.css";
import "element-ui/lib/theme-chalk/index.css";
import "./el/elmeui";
import "./assets/fonts/iconfont.css";
import "./assets/font_q15lnt3uutg/iconfont.css";
import axios from "axios";
import "./plugin/publicMethod";
// for bubble theme
import "./components/autoRegisterComponents.js";

import nprogress from "nprogress";
import ToTop from "./plugin/toTop";
import VueCookies from "vue-cookies";
import { VueJsonp } from "vue-jsonp";
import store from "./store";
import tiny from "./components/Tinymce";
import { Message } from "element-ui";
Vue.component("Tiny", tiny);
Vue.use(VueCookies);
Vue.use(ToTop);
Vue.use(VueJsonp);
import { debounce } from "./utils/methods";
const viewMessage = debounce((val) => {
    Message.error(val);
}, 100);
axios.interceptors.request.use((config) => {
    // console.log(VueCookies.get('token'));
    nprogress.start();
    config.headers.Token = VueCookies.get("token");

    return config;
});

Vue.prototype.bus = new Vue();
Vue.prototype.$http = axios;
axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;
axios.interceptors.response.use(
    (config) => {
        // console.log(config);
        nprogress.done();
        if (config.data.errorCode == 200) {
            return config;
        } else {
            // console.log(config.data.message)
            if (config.data.message.includes("重新登录")) {
                if (VueCookies.get("token")) {
                    viewMessage(config.data.message);
                }
                VueCookies.remove("token");
                router.push("/login");
            } else {
                viewMessage(config.data.message);
            }
            return config;
        }
    },
    (error) => {
        Message.error("接口出问题了，请联系后端");
        nprogress.done();
    }
);
Vue.config.productionTip = false;
// Vue.filter('dateFormat', function (originval) {
//   let dt = new Date(originval)
//   let y = dt.getFullYear()
//   let m = (dt.getMonth() + 1 + '').padStart(2, "0")
//   let d = (dt.getDate() + '').padStart(2, "0")
//   let hh = (dt.getHours() + '').padStart(2, "0")
//   let mm = (dt.getMinutes() + '').padStart(2, "0")
//   let ss = (dt.getSeconds() + '').padStart(2, "0")
//   return `${y}-${m}-${d} ${hh}:${mm}:${ss}`
// })
Vue.filter("staus", function(or) {
    if (or == 1) {
        return "显示";
    } else {
        return "隐藏";
    }
});
Vue.filter("st", function(e) {
    if (e == 1) {
        return "正常";
    } else {
        return "已禁用";
    }
});
new Vue({
    router,
    store,
    render: (h) => h(App),
}).$mount("#app");
