<template>
    <div>
        <el-upload
            ref="avatar"
            class="avatar-uploader"
            :action="upLoadUrl"
            accept=".jpg,.jpeg,.png,.gif,.ico"
            :headers="header"
            :show-file-list="false"
            :on-success="handleAvatarSuccess"
            :data="action"
        >
            <img v-if="banner" :src="showImg(banner)" class="avatar" />
            <i v-else class="el-icon-plus avatar-uploader-icon" />

            <div v-if="delImg" slot="tip">
                <div style="display:flex; align-items: center;">
                    <el-button size="small" type="danger" icon="el-icon-delete" circle @click="clears" />
                    <span class="specificationPrompt">
                        <span>查看UI图设计规范</span>
                        <el-image
                            style="width: 32px; height: 32px;margin-left: 5px;"
                            src="https://admin-1305822803.cos.ap-beijing.myqcloud.com/img/designSpecification.jpeg"
                            :preview-src-list="[
                                'https://admin-1305822803.cos.ap-beijing.myqcloud.com/img/designSpecification.jpeg',
                            ]"
                        >
                        </el-image>
                    </span>
                </div>
            </div>
            <template v-if="!tipNew">
                <div v-show="tip" slot="tip" class="el-upload__tip waring">
                    <span v-if="tip_text">请上传比例16:9的图片，否则会影响前台显示</span>
                    <span>{{ theCustom }}</span
                    ><br v-show="theCustom" />
                    <br v-if="tip_text" />
                    {{ theText }}
                </div>
            </template>
            <template v-else>
                <div slot="tip" class="el-upload__tip waring">
                    <slot name="tip"></slot>
                </div>
            </template>
        </el-upload>
    </div>
</template>

<script>
import VueCookies from "vue-cookies";
import { isHttp } from "@/utils/methods";
export default {
    name: "upImg",
    props: {
        action: {
            type: Object,
            default() {
                return {};
            },
        },
        banner: {
            type: String,
            default: "",
        },
        tip: {
            type: Boolean,
            default: false,
        },
        tip_text: {
            type: Boolean,
            default: true,
        },
        theCustom: {
            type: String,
            default: "",
        },
        theText: {
            type: String,
            default: "大小不可超过2M,只允许上传jpg,jpeg,png,gif,ico,svg格式的图片",
        },
        delImg: {
            type: Boolean,
            default: false,
        },
        fullUrl: {
            type: Boolean,
            default: false,
        },
        tipNew: {
            type: Boolean,
            default: false,
        },
        colUrl: {
            type: String,
            default: "",
        },
    },
    data() {
        return {
            baseurl: this.$store.state.env.cos,
            yu: "/" + this.$store.state.env.domain,
            // banner: '',
            // img:
            header: {
                Token: sessionStorage.getItem("token") || VueCookies.get("token"),
            },
        };
    },

    methods: {
        clears() {
            // this.$refs.avatar.clearFiles()
            this.$emit("event", "");
            this.$emit("update:banner", "");
        },
        handleAvatarSuccess(res, file) {
            if (res.errorCode == 200) {
                if (this.fullUrl) {
                    this.$emit("update:banner", this.baseurl + this.yu + res.data.url);
                    return;
                }
                this.$emit("event", res.data.url);
                this.$emit("update:banner", res.data.url);
                console.log(file);
            } else {
                this.$message.error(res.message);
            }
            // this.form.banner = URL.createObjectURL(file.raw)
        },
        showImg(val) {
            var reg = /(http|https):\/\/[\w.-]+(\/[^\s]*)?/;
            if (!reg.test(val)) {
                return this.baseurl + this.yu + val;
            } else {
                return val;
            }
        },
    },
    computed: {
        upLoadUrl() {
            if (this.colUrl) {
                if (isHttp(this.colUrl)) {
                    return this.colUrl;
                }
                return process.env.VUE_APP_BASE_URL + this.colUrl;
            }
            return this.$store.getters.imgUrl;
        },
    },
    // mounted() {
    //   console.log(this.action)
    // }
};
</script>

<style lang="less" scoped>
.waring {
    color: #fda400;
    line-height: 24px;
}
.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
}
.upload {
    position: relative;
    .clear {
        // display: none;
        position: absolute;
        top: 50%;
        right: 0;
        transform: translate(-150%, -50%);
    }
}
.specificationPrompt {
    display: flex;
    align-items: center;
    margin-left: 10px;
    // font-weight: bold;
    color: #f56c6c;
    font-size: 16px;
}
</style>
