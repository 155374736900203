export default [
  {
    path: '/inside_speaker',
    name: 'inside_speaker',
    meta: { title: '嘉宾资源库' },
    component: () => import('@/views/speaker/inside_speaker')
  },
  {
    path: '/inside_edit',
    name: 'inside_edit',
    meta: { title: '嘉宾资源库' },
    component: () => import('@/views/speaker/inside_speaker/inside_edit')
  }
]
