import { GET, POST, POSTJSON } from '@/utils/request'

/**
 * 通过后台接口上传
 * @param {} data
 */
export const uploadFile = data => {
  return POSTJSON('/admin/Upload/upload', {
    baseURL: process.env.VUE_APP_BASE_URL,
    data
  })
}

export const editInfo = data => {
  return POST('/admin/UserGuest/editInfo', {
    baseURL: process.env.VUE_APP_BASE_URL,
    data
  })
}
