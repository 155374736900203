<template>
    <div>
        <el-checkbox-group v-model="valueArr" @change="$emit('change')" :max="max">
            <slot></slot>
            <el-checkbox :label="item.key + ''" v-for="(item, index) in mapList" :key="index">{{
                item.value
            }}</el-checkbox>
        </el-checkbox-group>
    </div>
</template>

<script>
export default {
    name: "divCheckbox",
    props: {
        value: {
            type: String,
            default: "",
        },
        list: {
            type: Array,
            default: () => {
                return [];
            },
        },
        valueKey: {
            type: String,
            default: "key",
        },
        labelKey: {
            type: String,
            default: "value",
        },
        max: {
            type: [String, Number],
        },
    },

    computed: {
        valueArr: {
            get() {
                return this.value.split(",").filter(Boolean);
            },
            set(val) {
                this.$emit("input", val.filter(Boolean).toString());
            },
        },
        mapList() {
            return this.list.map((item) => {
                return {
                    ...item,
                    key: item[this.valueKey],
                    value: item[this.labelKey],
                };
            });
        },
    },
};
</script>

<style lang="less" scoped></style>
