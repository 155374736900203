let canRun;
export default {
    install(Vue) {
        Vue.prototype.$toData = function(value) {
            // 判断数据是否为空
            if (typeof value === "object") {
                if (!value) {
                    return false;
                }
                if (value.constructor == Array) {
                    return !!value.length;
                } else {
                    return !!Object.keys(value).length;
                }
            } else {
                return !!value;
            }
        };
        Vue.prototype.$unique = function(arr) {
            return [...new Set(arr)];
        };
        Vue.prototype.$toUrl = function(arr) {
            var a = document.createElement("a");
            a.href = arr;
            a.target = "_blank";
            a.click();
            a.remove();
        };
        Vue.prototype.$queryParams = function(obj, has) {
            const params = [];
            for (const key in obj) {
                if (obj.hasOwnProperty(key)) {
                    params.push(`${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`);
                }
            }
            return `${has ? "" : "?"}${params.join("&")}`;
        };

    },
};
