<template>
    <div>
        <el-select v-model="province" placeholder="省份" size="medium" style="width: 150px">
            <!-- <el-option label="全部" :value="-1" /> -->

            <el-option v-for="item in p_arr" :key="item.code" :label="item.name" :value="item.code" />
        </el-select>
        <el-select v-model="city" placeholder="城市" size="medium" style="width: 150px" class="ml">
            <!-- <el-option label="全部" :value="-1" /> -->
            <el-option v-for="item in c_arr" :key="item.code" :label="item.name" :value="item.code" />
        </el-select>
    </div>
</template>

<script>
export default {
    name: "region",
    props: {
        val1: {
            type: [String],
            default: "",
        },
        val2: {
            type: [String],
            default: "",
        },
    },
    data() {
        return {
            p_arr: [],
            c_arr: [],
        };
    },
    methods: {
        async getconfing() {
            const jj = await this.$store.dispatch("getProvince");
            this.p_arr = jj.data;
        },
        async getCity(val) {
            const jj = await this.$store.dispatch("getCity", val);
            if (jj.errorCode == 200) {
                this.c_arr = jj.data;
            }
        },
    },
    computed: {
        province: {
            get() {
                if (this.p_arr.length == 0) {
                    this.getconfing();
                }
                if (this.val1) {
                    this.getCity(this.val1);
                }
                return this.val1;
            },
            set(val) {
                this.$emit("update:val1", val);
                this.$emit("update:val2", "");
            },
        },
        city: {
            get() {
                return this.val2;
            },
            set(val) {
                this.$emit("update:val2", val);
            },
        },
    },
};
</script>

<style lang="less" scoped></style>
