export function debounce(fn, delay = 300) {
    let timer = null;
    return function() {
        let content = this;
        let args = arguments;
        if (timer) {
            clearTimeout(timer);
        }
        timer = setTimeout(() => {
            fn.apply(content, args);
        }, delay);
    };
}

export const extractArray = (value, str = ",") => {
    return value ? value.split(str).filter(Boolean) : [];
};

export const isHttp = (str) => {
    var reg = /(http|https):\/\/[\w.-]+(\/[^\s]*)?/;
    return reg.test(str);
};

export const forData = (obj, array) => {
    array.forEach((element) => {
        obj[element] = Array.isArray(obj[element]) ? obj[element] : extractArray(obj[element]);
    });
    return obj;
};
