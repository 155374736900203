<template>
    <div class="z-table" v-loading.fullscreen.lock="fullscreenLoading">
        <slot></slot>
        <slot name="empty" v-if="!$toData(value)">
            <div class="empty">
                <el-empty
                    v-if="empty"
                    :description="description"
                    image="https://static-cdn2.yfchuhai.com/www.yfchuhai.com/static/ucenter/index/emty_icon.png"
                ></el-empty>
            </div>
        </slot>
        <div class="footers">
            <divPagination
                v-if="$toData(value)"
                :search="reqData"
                :total="total"
                @size-change="onPageSizeChange"
                @currentChange="handleCurrentChange"
            ></divPagination>
        </div>
    </div>
</template>

<script>
export default {
    name: "z-table",
    props: {
        value: {
            type: [Array],
            default() {
                return [];
            },
        },
        page: {
            type: [String, Number],
            default: 1,
        },
        pageSize: {
            type: [String, Number],
            default: 10,
        },
        auto: {
            type: Boolean,
            default: true,
        },
        //分页存储
        storage: {
            type: [Boolean, Object],
            default: false,
        },
        description: {
            type: String,
            default() {
                return "暂无数据";
            },
        },
        empty: {
            type: Boolean,
            default: false,
        },
    },
    watch: {
        page: {
            handler(val) {
                this.$set(this.reqData, "page", val);
            },
            immediate: true,
        },
        pageSize: {
            handler(val) {
                this.$set(this.reqData, "pageSize", val);
            },
            immediate: true,
        },
    },
    data() {
        return {
            fullscreenLoading: false,
            reqData: {
                page: 1,
                pageSize: 10,
            },
            total: 0,
            path: this.$route.fullPath.toLocaleUpperCase(),
        };
    },
    watch: {},

    beforeDestroy() {
        if (this.storage) {
            console.log(JSON.stringify({ storage: this.storage, page: this.reqData.page }));
            sessionStorage.setItem(this.path, JSON.stringify({ storage: this.storage, page: this.reqData.page }));
        }
    },
    created() {
        if (this.storage) {
            let data = sessionStorage.getItem(this.path);
            if (!data) return;
            data = JSON.parse(data);
            let page = data.page;
            if (data.storage) this.$emit("update:storage", data.storage);
            if (page) {
                this.reqData.page = page;
            }
        }
    },
    mounted() {
        if (this.auto && this.$listeners["query"]) {
            this.getData();
        }
    },
    methods: {
        complete(data) {
            this.$emit("input", data.list || []);
            this.total = data.totalCount || 0;
            if (this.total >= this.reqData.pageSize) {
                if (data.list.length == 0) {
                    this.reqData.page--;
                    this.refresh();
                }
            }
            // this.fullscreenLoading = false;
        },
        reload() {
            this.reqData.page = 1;
            this.getData();
        },
        refresh() {
            this.$emit("query", this.reqData);
        },
        getData() {
            // this.fullscreenLoading = true;
            this.$emit("query", this.reqData);
        },
        onPageSizeChange(e) {
            this.reqData.pageSize = e;

            this.getData();
        },
        handleCurrentChange(e) {
            this.reqData.page = e;

            this.getData();
        },
    },
};
</script>

<style lang="less" scoped>
.footers {
    margin-top: 30px;
}
.empty {
    margin: 6vh 0;
}
</style>
