export default [
    {
        path: "/statistisc_list",
        name: "statistisc_list",
        meta: { title: "统计报表" },
        component: () => import("@/views/system/statistisc_list/index.vue"),
    },
    // {
    //     path: "/wangTest",
    //     name: "wangTest",
    //     meta: { title: "测试" },
    //     component: () => import("@/components/wang.vue"),
    // },
];
