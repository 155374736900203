<template>
  <div>
    <el-date-picker
      v-model="adEndTime"
      type="datetime"
      value-format="yyyy-MM-dd HH:mm:ss"
      format="yyyy-MM-dd HH:mm:ss"
      size="small"
      placeholder="选择日期时间"
    ></el-date-picker>
  </div>
</template>

<script>
export default {
  name: 'div-picker',
  props: {
    value: {
      type: [String, Array],
      default: ''
    }
  },
  data() {
    return {
    //   pickerOptions: {
    //     shortcuts: [
    //       {
    //         text: '今天',
    //         onClick(picker) {
    //           picker.$emit('pick', new Date())
    //         }
    //       },
    //       {
    //         text: '昨天',
    //         onClick(picker) {
    //           const date = new Date()
    //           date.setTime(date.getTime() - 3600 * 1000 * 24)
    //           picker.$emit('pick', date)
    //         }
    //       },
    //       {
    //         text: '一周前',
    //         onClick(picker) {
    //           const date = new Date()
    //           date.setTime(date.getTime() - 3600 * 1000 * 24 * 7)
    //           picker.$emit('pick', date)
    //         }
    //       }
    //     ]
    //   }
    }
  },
  computed: {
    adEndTime: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  }
}
</script>

<style lang="less" scoped></style>
