const rules = {
    req: function(val) {
        return { required: true, message: val, trigger: "blur" };
    },
    reqAll: function(val) {
        let obj = {};
        for (const key in val) {
            obj[key] = { required: true, message: val[key], trigger: ["blur", "change"] };
        }
        return obj;
    },
};
export default rules;
