<template>
  <div>
    <el-dialog
      :title="title"
      :visible.sync="show"
      width="30%"
      :center="cet"
      :before-close="handleClose"
    >
      <el-form
        v-if="id == 1"
        ref="formRef"
        :model="form"
        label-width="0px"
        :inline="false"
        size="normal"
      >
        <el-form-item prop="userId">
          <el-input v-model="form.userId" placeholder="请输入用户ID" />
        </el-form-item>
        <el-form-item>
          <div style="text-align:center;">
            <el-button type="primary" @click="onSubmit">创 建</el-button>
          </div>
        </el-form-item>
      </el-form>
      <el-form
        v-if="id == 2"
        ref="formRef"
        :model="form"
        label-width="0px"
        :inline="false"
        size="normal"
      >
        <el-form-item prop="userId">
          <el-input
            v-model="form.name"
            placeholder="输入渠道名称，只能输入字母和数字"
          />
        </el-form-item>
        <el-form-item v-if="!edit">
          <div style="text-align:center;">
            <el-button type="primary" @click="onSubmit">生 成</el-button>
          </div>
        </el-form-item>
        <el-form-item v-if="edit">
          <div style="text-align:center;">
            <el-button type="primary" @click="onEdit">修 改</el-button>
          </div>
        </el-form-item>
      </el-form>
      <el-form
        v-if="id == 3"
        ref="formRef"
        :model="form"
        :rules="rules"
        label-width="80px"
        :inline="false"
        size="normal"
      >
        <el-form-item label="本站会员">
          <el-select v-model="form.type">
            <el-option label="是" value="0" />
            <el-option label="否" value="1" />
          </el-select>
        </el-form-item>
        <el-form-item v-if="form.type == 0" prop="userId" label="用户ID">
          <el-input v-model="form.userId" placeholder="请输入用户ID" />
        </el-form-item>
        <el-form-item v-if="form.type == 1" prop="name" label="姓名">
          <el-input v-model="form.name" placeholder="请输入姓名" />
        </el-form-item>
        <el-form-item v-if="form.type == 1" prop="company" label="公司">
          <el-input v-model="form.company" placeholder="请输入公司" />
        </el-form-item>
        <el-form-item v-if="form.type == 1" prop="job" label="职位">
          <el-input v-model="form.job" placeholder="请输入职位" />
        </el-form-item>
        <el-form-item v-if="form.type == 1" prop="phone" label="电话">
          <el-input v-model="form.phone" placeholder="请输入电话" />
        </el-form-item>
        <!-- <el-form-item
          v-if="form.type == 1"
          prop="businessEmail"
          label="企业邮箱"
        >
          <el-input
            v-model="form.businessEmail"
            placeholder="请输入企业邮箱"
          />
        </el-form-item> -->
        <el-form-item v-show="form.type == 1" prop="email" label="企业邮箱">
          <el-input v-model="form.email" placeholder="请输入企业邮箱" />
        </el-form-item>
        <el-form-item prop="ticketId" label="票种">
          <el-select v-model="form.ticketId" placeholder="票种">
            <el-option
              v-for="item in form.ticketList"
              :label="item.name"
              :value="item.id"
			  :key="item.id"
            />
          </el-select>
        </el-form-item>

        <el-form-item v-if="!edit">
          <div style="text-align:center;">
            <el-button type="primary" @click="onSubmit">创 建</el-button>
          </div>
        </el-form-item>
        <el-form-item v-if="edit">
          <div style="text-align:center;">
            <el-button type="primary" @click="onEdit">修 改</el-button>
          </div>
        </el-form-item>
      </el-form>
      <el-form
        v-if="id == 4"
        ref="formRef"
        :model="form"
        label-width="0px"
        :inline="false"
        size="normal"
      >
        <el-form-item prop="remark">
          <el-input
            v-model="form.remark"
            type="textarea"
            placeholder="拒绝原因，50字以内"
            :max="50"
          />
        </el-form-item>
        <el-form-item v-if="!edit">
          <div style="text-align:center;">
            <el-button type="primary" @click="onSubmit">保 存</el-button>
          </div>
        </el-form-item>
        <el-form-item v-if="edit">
          <div style="text-align:center;">
            <el-button type="primary" @click="onEdit">修 改</el-button>
          </div>
        </el-form-item>
      </el-form>
      <el-form
        v-if="id == 5"
        ref="formRef"
        :model="form"
        label-width="80px"
        :inline="false"
        size="normal"
      >
        <el-form-item label="积分数额">
          <el-input v-model="form.bonus" placeholder="积分数额" />
        </el-form-item>
        <el-form-item label="用户ID">
          <el-input v-model="form.userId" placeholder="ID" />
        </el-form-item>
        <el-form-item v-if="!edit">
          <div style="text-align:center;">
            <el-button type="primary" @click="onSubmit">确 认</el-button>
          </div>
        </el-form-item>
        <el-form-item v-if="edit">
          <div style="text-align:center;">
            <el-button type="primary" @click="onEdit">修 改</el-button>
          </div>
        </el-form-item>
      </el-form>
      <el-form
        v-if="id == 6"
        ref="formRef"
        :model="form"
        label-width="80px"
        :inline="false"
        size="normal"
      >
        <el-form-item label="快递单号">
          <el-input v-model="form.expressNo" />
        </el-form-item>
        <el-form-item v-if="!edit">
          <div style="text-align:center;">
            <el-button type="primary" @click="onSubmit">确 认</el-button>
          </div>
        </el-form-item>
        <el-form-item v-if="edit">
          <div style="text-align:center;">
            <el-button type="primary" @click="onEdit">修 改</el-button>
          </div>
        </el-form-item>
      </el-form>
      <el-form
        v-if="id == 7"
        ref="formRef"
        :model="form"
        label-width="80px"
        :inline="false"
        size="normal"
      >
        <el-form-item label="名字">
          {{ form.name }}
        </el-form-item>
        <el-form-item label="手机">
          {{ form.phone }}
        </el-form-item>
        <el-form-item label="地址">
          {{ form.address + form.addressDetail }}
        </el-form-item>
      </el-form>

      <!-- 添加外部活动助手 -->
      <el-form
        v-if="id == 8"
        ref="formRef"
        :model="form"
        label-width="80px"
        :inline="false"
        size="normal"
      >
        <el-form-item label="名字">
          <el-input v-model="form.name" placeholder="输入名字" />
        </el-form-item>
        <el-form-item label="手机">
          <el-input v-model="form.phone" placeholder="输入手机号" />
        </el-form-item>
        <el-form-item v-if="!edit">
          <div style="text-align:center;">
            <el-button type="primary" @click="onSubmit">确 认</el-button>
          </div>
        </el-form-item>
      </el-form>
      <!-- 活动广告设置 -->
      <el-form
        v-if="id == 9"
        ref="formRef"
        :model="form"
        label-width="80px"
        :inline="false"
        size="normal"
      >
        <el-form-item label="位置">
          <!-- <el-input v-model="form.id" placeholder="输入名字" /> -->
          <!-- <el-radio-group v-model="form.adLocation">
            <el-radio label="1">首页</el-radio>
            <el-radio label="2">圈子</el-radio>
            <el-radio label="3">企服</el-radio>
            <el-radio label="4">合作</el-radio>
          </el-radio-group> -->
          <el-checkbox-group v-model="form.adLocation">
            <el-checkbox
              v-for="(item, index) in {
                1: '首页',
                2: '圈子',
                3: '企服',
                4: '合作'
              }"
              :label="index"
              :key="index"
              >{{ item }}</el-checkbox
            >
          </el-checkbox-group>
        </el-form-item>
        <el-form-item label="banner图">
          <up-img
            :banner.sync="form.adBanner"
            :action="{ action: 'ad' }"
            tip
            :tip_text="false"
            theCustom="上传比例大概在586x780左右的图片"
          ></up-img>
        </el-form-item>
        <el-form-item label="结束时间">
          <el-date-picker
            v-model="form.adEndTime"
            type="datetime"
            value-format="yyyy-MM-dd HH:mm:ss"
            format="yyyy-MM-dd HH:mm:ss"
            size="small"
            placeholder="选择日期时间"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item v-if="!edit">
          <div style="text-align:center;">
            <el-button type="primary" @click="onSubmit">确 认</el-button>
          </div>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
// imp
import rules from '@/utils/rules.js'
export default {
  name: 'popup',
  props: {
    title: {
      type: String,
      default: '提示'
    },
    cet: {
      type: Boolean,
      default: false
    },
    id: {
      type: [Number, String],
      default: '1'
    }
  },
  data() {
    return {
      show: false,
      form: {
        userId: ''
      },
      rules: {
        name: [rules.req('请输入姓名')],
        company: [rules.req('请输入公司')],
        job: [rules.req('请输入职位')],
        phone: [rules.req('请输入电话')],
        email: [rules.req('请输入企业邮箱')],
        ticketId: [rules.req('请输入票种')]
      },
      edit: false
    }
  },
  //   watch: {
  //     show(newValue, oldValue) {
  //       console.log(this.id)
  //     }
  //   },
  methods: {
    handleClose() {
      this.show = false
      this.$refs.formRef.resetFields()
    },
    async onSubmit() {
      if (this.id == 1) {
        var { data: res } = await this.$http.post(
          '/admin/Cooperation/addWhiteList',
          this.form
        )
      } else if (this.id == 2) {
        var { data: res } = await this.$http.post(
          '/admin/Activity/channelAdd',
          this.form
        )
      } else if (this.id == 3) {
        await this.$refs['formRef'].validate(valid => {})
        var { data: res } = await this.$http.post(
          '/admin/Activity/ApplyAdd',
          this.form
        )
      } else if (this.id == 4) {
        var { data: res } = await this.$http.post(
          '/admin/Activity/applyCheck',
          this.form
        )
      } else if (this.id == 5) {
        var { data: res } = await this.$http.post(
          '/admin/User/Bonus',
          this.form
        )
      } else if (this.id == 6) {
        this.$emit('submit', this.form)
        return
      } else if (this.id == 8) {
        var { data: res } = await this.$http.post(
          '/admin/Activity/managerOtherAdd',
          this.form
        )
      } else if (this.id == 9) {
        var { data: res } = await this.$http.post('/admin/Activity/editAd', {
          ...this.form,
          adLocation: this.form.adLocation.toString()
        })
      }

      if (res.errorCode == 200) {
        this.$refs.formRef.resetFields()
        this.$message.success(res.message)
        this.$emit('getlist')
        this.show = false
      }
    },
    async onEdit() {
      if (this.id == 2) {
        var { data: res } = await this.$http.post(
          '/admin/Activity/channelEdit',
          this.form
        )
      }
      if (res.errorCode == 200) {
        this.$message.success(res.message)
        this.show = false
        this.$emit('getlist')
        this.$refs.formRef.resetFields()
      }
    },
    initForm(val) {
      this.show = true
      this.form = val
    }
  }
}
</script>

<style lang="less" scoped></style>