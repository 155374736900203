<template>
    <div class="view-text">
        <div class="lss">
            <fuzzy-selection type="8" ref="fuzzy" :value.sync="addValue"></fuzzy-selection>
            <el-button type="primary" @click="add" size="small" style="margin-left: 10px;">添加</el-button>
        </div>
        <div class="content">
            <div class="row flex-align" v-for="(item, index) in list" :key="index">
                <span class="text clamp-1"> {{ item.name }}（{{ item.activityName }}）</span>
                <el-checkbox class="alternative" v-model="item.check">必选</el-checkbox>
                <el-tooltip class="item" effect="dark" content="该票种与主票种同时报名时在我的活动列表中不显示主票种" placement="top-start">
                    <el-checkbox class="alternative" v-model="item.repeated">与主票种重复</el-checkbox>
                </el-tooltip>
               
                <i class="el-icon-close" @click="del(index)">
                    删除
                </i>
            </div>
        </div>
    </div>
</template>

<script>
import fuzzySelection from "./fuzzySelection.vue";
export default {
    components: {
        fuzzySelection,
    },
    props: {
        value: {
            type: [String, Array],
            default() {
                return "";
            },
        },
        initList: {
            type: [String, Array],
            default() {
                return [];
            },
        },
    },
    data() {
        return {
            addValue: "",
            list: [],
        };
    },
    watch: {
        list: {
            handler(newValue, oldValue) {
                var arr = newValue.map((item) => {
                    return {
                        id: item.id,
                        selected: item.check ? 1 : 0,
                        repeated: item.repeated ? 1 : 0,
                    };
                });
                this.$emit("input", JSON.stringify(arr));
            },
            deep: true, // true 深度监听
        },
        initList: {
            handler(val) {
                this.list = val.map((item) => {
                    return {
                        ...item,
                        name: item.ticketName,
                        check: item.selected ? true : false,
                        repeated: item.repeated ? true : false,
                    };
                });
            },
            immediate: true,
        },
    },
    methods: {
        add() {
            if (!this.addValue) return this.$message.error("不能为空");
            var arr = this.$refs.fuzzy.options;
            var obj = arr.find((item) => item.id == this.addValue);
            this.list.push({ ...obj });
            this.addValue = "";
        },
        del(index) {
            this.list.splice(index, 1);
        },
    },
};
</script>

<style lang="less" scoped>
.view-text {
}
.lss {
    display: flex;
}
.content {
    padding: 10px 0;
    .el-icon-close {
        color: red;
        flex-shrink: 0;
        margin-left: 30px;
        cursor: pointer;
    }
    .alternative {
        flex-shrink: 0;
    }
}
</style>
