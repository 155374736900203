<template>
    <div>
        <el-dialog title="添加到短信收件人" :visible.sync="dialogVisible" width="30%">
            <el-form :model="form" ref="form" :rules="rules" label-width="auto" :inline="false" size="normal">
                <el-form-item label="收件人标签">
                    <fuzzy-selection
                        tipName="收件人"
                        :type="13"
                        width="100%"
                        :value.sync="form.tagId"
                    ></fuzzy-selection>
                </el-form-item>
                <!-- <el-form-item label="类型">
                    <el-select v-model="form.type" clearable>
                        <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item> -->
                <!-- <el-form-item label="短信模板">
                    <fuzzy-selection
                        tipName="短信模板标题"
                        :type="12"
                        width="100%"
                        :value.sync="form.templateId"
                    ></fuzzy-selection>
                </el-form-item>
                <el-form-item label="发送时间">
                    <el-radio v-model="form.sendTimeType" :label="0">立即发送</el-radio>
                    <el-radio v-model="form.sendTimeType" :label="1">定时发送</el-radio>
                    <div v-if="form.sendTimeType == 1">
                        <div-picker v-model="form.sendTime"> </div-picker>
                    </div>
                </el-form-item> -->
            </el-form>

            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="deliveryConfirmation">确认</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import fuzzySelection from "@/components/fuzzySelection.vue";
export default {
    name: "send-sms",
    components: {
        fuzzySelection,
    },
    props: {
        type: {
            type: String,
            default() {
                return "1";
            },
        },
    },
    data() {
        return {
            dialogVisible: false,
            form: {
                // title: "",
                // templateId: "",
                // sendTimeType: 0,
                // sendTime: "",
                tagId: "",
            },

            rules: {},
            detail: {},
        };
    },
    methods: {
        init(val) {
            this.dialogVisible = true;
            // this.form = this.$options.data.call(this).form;
            this.detail = val || {};
        },
        async deliveryConfirmation() {
            let { data: res } = await this.$http.post("/admin/Sms/importSmsUser", {
                ...this.form,
                params: JSON.stringify(this.detail),
                type: +this.type,
            });
            if (res.errorCode == 200) {
                this.$message.success(res.message);
                this.dialogVisible = false;
            }
        },
    },
};
</script>

<style lang="scss" scoped></style>
