<template>
    <div id="app">
        <!-- <router-view></router-view> -->
        <div id="app" v-if="falg">
            <router-view></router-view>
        </div>
    </div>
</template>

<style>
body {
    font-family: "PingFang SC", "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
}
@import "./assets/font_1x6l4bwg968/iconfont.css";
</style>
<script>
export default {
    data() {
        return {
            falg: true,
        };
    },
    watch: {},
    methods: {
        async getPhone() {
            console.log(123);
            if (!this.$cookies.get("token") || this.$route.path == "/login") {
                this.falg = false;
                let { data: res } = await this.$http.post("/admin/AuthMember/checkPhone", {
                    phone: this.$route.query.phone,
                });
                if (res.errorCode != 200) {
                    if (location.href.includes("localhost:")) return;
                    location.href = "https://www.yfchuhai.com/404";
                    return;
                }
            }
            this.falg = true;
        },
    },
    created() {
        this.getPhone();
    },
};
</script>
