export default [
    {
        path: "/activity_sign",
        name: "activity_sign",
        meta: { title: "签到打印" },
        component: () => import("@/views/activity/activity_sign.vue"),
    },
    {
        path: "/activity_speaker",
        name: "activity_speaker",
        meta: { title: "演讲嘉宾" },
        component: () => import("@/views/activity/activity_speaker.vue"),
    },
    {
        path: "/activity_speaker_edit",
        name: "activity_speaker_edit",
        meta: { title: "演讲嘉宾" },
        component: () => import("@/views/activity/activity_speaker/edit.vue"),
    },
    {
        path: "/activity_blacklist",
        name: "activity_blacklist",
        meta: { title: "演讲嘉宾" },
        component: () => import("@/views/activity/activity_blacklist/"),
    },
    {
        path: "/activity_speaker_ppt",
        name: "activity_speaker_ppt",
        meta: { title: "演讲嘉宾PPT" },
        component: () => import("@/views/activity/activity_speaker_ppt/"),
    },
    {
        path: "/activity_speaker_ppt/add",
        name: "activity_speaker_ppt_add",
        meta: { title: "演讲嘉宾PPT" },
        component: () => import("@/views/activity/activity_speaker_ppt/add.vue"),
    },
    {
        path: "/activity_speaker_ppt/log",
        name: "activity_speaker_ppt_log",
        meta: { title: "演讲嘉宾PPT" },
        component: () => import("@/views/activity/activity_speaker_ppt/log.vue"),
    },
    {
        path: "/activity_ppt_list",
        name: "activity_ppt_list",
        meta: { title: "演讲嘉宾PPT" },
        component: () => import("@/views/activity/activity_ppt_list"),
    },
    {
        path: "/activity_sign_log_list",
        name: "activity_sign_log_list",
        meta: { title: "签到记录" },
        component: () => import("@/views/activity/activity_sign_log_list"),
    },
];
