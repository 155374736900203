/* eslint-disable no-undef */
import axios from 'axios'
import router from 'vue-router'
import { MessageBox, Message } from 'element-ui'
import $store from '@/store'
import VueCookies from 'vue-cookies'
import QS from 'qs'
import _ from 'lodash'

let isTipLogout = false

const disabledLoginCode = [
  601,
  602,
  603,
  604,
  605,
  606,
  607,
  608,
  609,
  610,
  611,
  101008,
  101007,
  101006
]

export const HTTP = (url, options) => {
  const axiosOptions = { isAuth: true, withCredentials: true, ...options }

  axiosOptions.baseURL = options.baseURL || process.env.VUE_APP_BASE_URL
  axiosOptions.headers = options.headers || {
    'content-type': 'application/json;charset=UTF-8'
  }
  axiosOptions.method = options.method || 'get'
  axiosOptions.timeout = options.timeout || 5 * 60 * 1000
  axiosOptions.responseType = options.responseType || ''

  if (axiosOptions.isAuth) {
    axiosOptions.headers['Token'] = VueCookies.get('token')
  }

  if (options.data && _.includes(['get'], options.method)) {
    axiosOptions.params = options.data
  } else if (
    options.data &&
    _.includes(['post', 'put'], options.method) &&
    axiosOptions.headers['content-type'] === 'application/x-www-form-urlencoded'
  ) {
    axiosOptions.data = QS.stringify(options.data)
  } else {
    axiosOptions.data = options.data
  }

  return new Promise((resolve, reject) => {
    axios(url, { ...axiosOptions })
      .then(res => {
        const status = res.status.toString()
        if (status.startsWith('2')) {
          const code = res.data.errorCode
          if (
            _.includes(
              res.headers['content-type'],
              'application/octet-stream'
            ) &&
            axiosOptions.responseType &&
            _.includes(['blob', 'arraybuffer'], axiosOptions.responseType)
          ) {
            const disposition = res.headers['content-disposition']
            const filename = disposition.match(/filename=(\S*?)(;|$)/)[1]
            resolve({ data: res.data, filename })
          } else if (code === 200) {
            resolve(res.data)
          } else if (disabledLoginCode.includes(code)) {
            // 登录状态异常
            if (!isTipLogout) {
              isTipLogout = true
              MessageBox.confirm(_.get(res.data, 'message'), {
                confirmButtonText: '确认',
                cancelButtonText: '取消',
                type: 'warning'
              })
                .then(() => {
                  isTipLogout = false
                  $store.dispatch('user/resetToken').then(() => {
                    location.reload()
                  })
                })
                .catch(() => {
                  isTipLogout = false
                })
            }
          } else if (String(code).startsWith('5')) {
            // 服务端错误
            reject({
              handled: true,
              code,
              message: _.get(res.data, 'message')
            })
            Message({
              message: _.get(res.data, 'message'),
              type: 'error',
              duration: 5 * 1000
            })
          } else {
            reject({
              handled: false,
              code,
              message: _.get(res.data, 'message'),
              data: res.data ? res.data : {}
            })
          }
        } else {
          const errCode = !navigator.onLine ? 2 : 1
          _defaultErrorHandler(errCode)
        }
      })
      .catch(err => {
        const interrupt = axios.isCancel(err)
        reject({
          handled: true,
          message: interrupt ? '中断请求' : err,
          interrupt
        })
        if (interrupt) {
          console.log('中断请求')
          return
        }
        Message({
          message: err.message,
          type: 'error',
          duration: 5 * 1000
        })
      })
  })
}

/**
 * 根据项目情况自定义
 */
const _tips = {
  1: {
    title: '服务繁忙,请稍后重试'
  },
  2: {
    title: '断网了,请检查网络'
  }
}

const _defaultErrorHandler = errCode => {
  errCode = errCode || 1

  Message({
    message: _tips[errCode].title || 'Error',
    type: 'error',
    duration: 5 * 1000
  })

  if (!_tips[errCode].name) return

  router.replace({
    name: _tips[errCode].name
  })
}

export const GET = (url = '', options = {}) =>
  HTTP(url, {
    ...options,
    method: 'get'
  })

export const POST = (url = '', options = {}) =>
  HTTP(url, {
    method: 'post',
    headers: {
      'content-type': 'application/x-www-form-urlencoded'
    },
    ...options
  })

export const POSTJSON = (url = '', options = {}) =>
  HTTP(url, {
    ...options,
    method: 'post'
  })

export const PUT = (url = '', options = {}) =>
  HTTP(url, {
    ...options,
    method: 'put',
    headers: {
      'content-type': 'application/x-www-form-urlencoded'
    }
  })

export const PUTJSON = (url = '', options = {}) =>
  HTTP(url, {
    ...options,
    method: 'put'
  })

export const DELETE = (url = '', options = {}) =>
  HTTP(url, {
    ...options,
    method: 'delete'
  })
