<template>
    <div class="login_container">
        <div class="login_box">
            <img src="../assets/logo.png" alt="" class="img" />
            <h1 style="text">扬帆出海后台管理系统</h1>
            <el-form ref="loginFormRef" label-width="0px" class="login_form" :model="login_form" :rules="rules">
                <!-- 用户名 -->
                <el-form-item prop="phone">
                    <el-input v-model="login_form.phone" prefix-icon="el-icon-phone" />
                </el-form-item>
                <!-- 密码 -->
                <el-form-item prop="code">
                    <el-input v-model="login_form.code" type="text">
                        <template slot="append">
                            <el-button @click.native="getcode">
                                <Count ref="count"></Count>
                            </el-button>
                        </template>
                    </el-input>
                </el-form-item>
                <!-- 按钮区域 -->
                <el-form-item class="btns">
                    <el-button type="primary" :loading="btn" @click="login">{{ "登录" }}</el-button>
                    <el-button type="info" @click="resetloginForm">重置</el-button>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>

<script>
// import $ from 'jquery'
// import 'https://cdn.staticfile.org/jquery-cookie/1.4.1/jquery.cookie.min.js'
import Count from "./countDowns/index.vue";
export default {
    components: {
        Count,
    },
    data() {
        return {
            // 这是登陆
            login_form: {
                phone: "",
                code: "",
            },
            rules: {
                phone: [
                    { required: true, message: "请输入手机号", trigger: "blur" },
                    // { min: 3, max: 10, message: '长度在 3 到 10个字符', trigger: 'blur' }
                ],
                code: [
                    { required: true, message: "请输入验证码", trigger: "change" },
                    // { min: 6, max: 15, message: "长度在 6 到 15个字符", trigger: "blur" },
                ],
            },
            btn: false,
        };
    },
    created() {
        if (this.$route.query.phone) {
            this.login_form.phone = this.$route.query.phone;
        }
    },
    methods: {
        resetloginForm() {
            // console.log(this);
            this.$refs.loginFormRef.resetFields();
        },
        async getcode() {
            if (this.$refs.count.timer) return;
            if (!this.login_form.phone) {
                this.$message({
                    message: "请输入手机号",
                    type: "warning",
                });
                return;
            }
            const graphicsRes = await this.$store.dispatch("checkGraphics");
            this.$refs.count.countDown();
            // 发送手机验证码

            const { ticket, randstr } = graphicsRes;
            const reqData = {
                phone: this.login_form.phone,
                ticket,
                randStr: randstr,
                terminal: "pc",
            };

            let {
                data: {
                    data: { code },
                },
            } = await this.$http.post("/api/Common/sendSmsCode", reqData);
            if (code) {
                this.login_form.code = code;
            }
        },
        login() {
            this.$refs.loginFormRef.validate(async (valid) => {
                if (!valid) return;
                // const graphicsRes = await this.$store.dispatch("checkGraphics");
                // var { ticket } = graphicsRes;
                // console.log(graphicsRes)
                this.btn = true;
                
                const res = await this.$http.post("/admin/AuthMember/loginByPhone", {
                    ...this.login_form,
                });
                if (res.data.errorCode != 200) {
                    this.btn = false;
                    return;
                }

                this.$message.success(res.data.message);
                // console.log(res)
                sessionStorage.setItem("token", res.data.data.token);
                sessionStorage.setItem("username", res.data.data.username);
                const meu = res.data.data.authAccessList.menus;
                const but = res.data.data.authAccessList.buttons;
                sessionStorage.setItem("menu", JSON.stringify(meu));
                this.$cookies.set("token", res.data.data.token, "0");
                // this.$cookies.set('username', res.data.data.username)
                this.$cookies.set("phone", res.data.data.phone);
                this.$cookies.set("username", res.data.data.realName);
                localStorage.setItem("menu", JSON.stringify(meu));
                localStorage.setItem("but", JSON.stringify(but));
                this.$store.state.btn_if = but;
                this.$store.state.name = meu;
                this.$router.push("/home");
                this.btn = false;
            });
        },
    },
};
</script>

<style lang="less" scoped>
.login_container {
    background-color: #7e9bb9;
    height: 100%;
}
.login_box {
    width: 450px;
    height: 300px;
    background-color: #fff;
    border-radius: 3px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    .avatar_box {
        height: 130px;
        width: 130px;
        border: 1px solid #eee;
        border-radius: 50%;
        padding: 10px;
        box-shadow: 0 0 10px #ddd;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: #fff;
        img {
            width: 100%;
            height: 100%;
            border-radius: 50%;
            background-color: #fff;
        }
    }
}
.login_form {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 0 20px;
    box-sizing: border-box;
}
.btns {
    display: flex;
    justify-content: space-around;
}
.bc {
    width: 100%;
    height: 100%;
}
h1 {
    text-align: center;
    line-height: 100px;
    font-size: 25px;
    font-weight: bold;
}
.img {
    position: absolute;
    top: -70px;
    left: 130px;
}
</style>
