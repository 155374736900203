<template>
  <div>
    <el-image
      :style="{ width: width, height: auto ? 'auto' : width }"
      :src="fullSrc"
      :preview-src-list="viewList"
    >
    </el-image>
  </div>
</template>

<script>
export default {
  name: 'pImage',
  props: {
    width: {
      type: String,
      default: '80px'
    },
    src: '',
    view: {
      type: Boolean,
      default() {
        return true
      }
    },
    auto: {
      type: Boolean,
      default() {
        return false
      }
    }
  },
  computed: {
    fullSrc() {
      if (this.src.includes('http')) return this.src
      return this.$store.getters.imgCol + this.src
    },
    viewList() {
      if (this.view) {
        return [this.fullSrc]
      }
      return []
    }
  }
}
</script>

<style lang="less" scoped></style>
