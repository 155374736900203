<template>
    <div class="load-buttom">
        <el-button :size="size" :icon="icon" :type="type" :loading="loading" @click="toClick">
            <slot></slot>
        </el-button>
    </div>
</template>

<script>
export default {
    name: "load-button",
    props: {
        click: {
            type: Function,
            default: () => {},
        },
        type: {
            type: String,
            default: "primary",
        },
        size: {
            type: String,
            default: "small",
        },
        icon: {
            type: String,
            default: "",
        },
    },
    data() {
        return {
            loading: false,
        };
    },
    methods: {
        toClick() {
            this.loading = true;
            this.click(this.done);
        },
        done() {
            this.loading = false;
        },
    },
};
</script>

<style lang="less" scoped>
.load-buttom {
    display: inline-block;
}
</style>
