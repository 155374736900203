<template>
    <div>
        <el-dialog :visible.sync="show" :width="width" :title="title">
            <slot></slot>
            <div slot="footer" class="dialog-footer" v-show="footer">
                <el-button type="primary" @click="toAudit">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
export default {
    name: "pop",
    props: {
        title: {
            type: String,
            default: "",
        },
        width: {
            type: String,
            default: "30%",
        },
        footer: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            show: false,
            row: null,
            resolve() {},
        };
    },
    methods: {
        toAudit() {
            this.$emit("toAudit", this.row);
        },
    },
};
</script>

<style lang="less" scoped></style>
