<template>
    <div>
        <el-pagination
            :current-page="search.page"
            background
            :page-sizes="[3, 5, 10, 15]"
            :page-size="search.pageSize"
            layout="total, prev, pager, next,jumper"
            :total="total"
            @current-change="$emit('currentChange',$event)"
        />
    </div>
</template>

<script>
export default {
    name: 'divPagination',
    props: {
        search: {
            type: [Object],
            default() {
                return {}
            },
        },
        total: [Number],
    },
}
</script>

<style lang="less" scoped>
</style>