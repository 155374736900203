export default [
  {
    path: '/enterprise_category_list',
    name: 'enterprise_category_list',
    meta: { title: '企业分类' },
    component: () => import('@/views/enterprise/enterprise_category_list.vue')
  },
  {
    path: '/enterprise_check_list',
    name: 'enterprise_check_list',
    meta: { title: '企业分类' },
    component: () => import('@/views/enterprise/enterprise_check_list.vue')
  },
  {
    path: '/industrialIn_iformation_edit',
    name: 'industrialIn_iformation_edit',
    meta: { title: '企业分类' },
    component: () =>
      import('@/views/enterprise/industrialIn_iformation/edit.vue')
  },
  {
    path: '/enterprise_article_check_list',
    name: 'enterprise_article_check_list',
    meta: { title: '企业文章审核' },
    component: () =>
      import('@/views/enterprise/enterprise_article_check_list.vue')
  },
  {
    path: '/enterprise_products',
    name: 'enterprise_products',
    meta: { title: '企业产品' },
    component: () => import('@/views/enterprise/enterprise_Products/index.vue')
  },
  {
    path: '/enterprise_products_edit',
    name: 'enterprise_products_edit',
    meta: { title: '企业产品' },
    component: () => import('@/views/enterprise/enterprise_Products/edit.vue')
  },
  {
    path: '/user_enterprise_edit_check',
    name: 'user_enterprise_edit_check',
    meta: { title: '企服信息修改审核' },
    component: () => import('@/views/enterprise/user_enterprise_edit_check')
  },
  {
    path: '/enterprise_case_check_list',
    name: 'enterprise_case_check_list',
    meta: { title: '企业案例审核' },
    component: () => import('@/views/enterprise/enterprise_case_check_list')
  }
]
