<template>
    <div>
        <el-date-picker v-model="time" :value-format="formatStr" :format="formatStr" :type="dataType" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"> </el-date-picker>
    </div>
</template>

<script>
import { extractArray } from "@/utils/methods";
export default {
    name: "div-date-picker",
    props: {
        begin: {
            type: String,
            default: "",
        },
        end: {
            type: String,
            default: "",
        },
        timeStr: {
            type: String,
            default: "",
        },
        dataType: {
            type: String,
            default: "datetimerange",
        },
    },
    data() {
        return {};
    },
    computed: {
        time: {
            get() {
                if (this.begin) {
                    return [this.begin, this.end];
                }
                if (this.timeStr) {
                    return extractArray(this.timeStr, "~");
                }
                return "";
            },
            set(val) {
                this.$emit("update:begin", val[0]);
                this.$emit("update:end", val[1]);
                this.$emit("update:timeStr", val.join("~"));
            },
        },
        formatStr: {
            get() {
                if (this.dataType == "datetimerange") {
                    return "yyyy-MM-dd HH:mm:ss";
                }
                return "yyyy-MM-dd";
            },
        },
    },
};
</script>

<style lang="less" scoped></style>
