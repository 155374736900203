<template>
    <div>
        <el-upload
            ref="upload"
            :action="upLoadUrl"
            :headers="header"
            :on-preview="handlePreview"
            :data="action"
            :on-success="handleAvatarSuccess"
            :on-remove="handleRemove"
            :before-remove="beforeRemove"
            :accept="accept"
            multiple
            :on-exceed="handleExceed"
            :file-list="fileList"
        >
            <el-button size="small" type="primary">
                <slot>
                    点击上传
                </slot>
            </el-button>
            <div slot="tip" class="el-upload__tip waring">
                {{ tip }}
            </div>
        </el-upload>
    </div>
</template>

<script>
import VueCookies from "vue-cookies";
import { isHttp } from "@/utils/methods";
export default {
    name: "upFile",
    props: {
        value: {
            type: String,
            default: "",
        },
        tip: {
            type: String,
            default: "点击下面文件下载",
        },
        type: {
            type: String,
            default: "document",
        },
        full: {
            type: Boolean,
            default: false,
        },
        colUrl: {
            type: String,
            default: "",
        },
        accept: {
            type: String,
            default: "",
        },
    },
    data() {
        return {
            baseurl: this.$store.state.env.cos,
            yu: "/" + this.$store.state.env.domain,
            fileList: [],
            header: {
                Token: sessionStorage.getItem("token") || VueCookies.get("token"),
            },
            //   action: {
            //     action: sessionStorage.getItem('action'),
            //     type: 'document'
            //   }
        };
    },
    watch: {
        value: {
            //   console.log(val)
            handler(val, oldName) {
                if (!val) return;
                if (isHttp(val)) {
                    this.fileList = [
                        {
                            name: val.slice(val.lastIndexOf("/") + 1, val.length),
                            url: val,
                        },
                    ];
                } else {
                    this.fileList = [
                        {
                            name: val.slice(val.lastIndexOf("/") + 1, val.length),
                            url: this.baseurl + this.yu + val,
                        },
                    ];
                }
            },
            immediate: true,
        },
    },
    methods: {
        handleRemove(file, fileList) {
            console.log(file, fileList);
            if (fileList.length == 0) {
                this.$emit("input", fileList.toString());
                this.$emit("update:value", fileList.toString());
            }
        },
        handleAvatarSuccess(res) {
            if (res.errorCode == 200) {
                this.$refs.upload.clearFiles();
                if (this.full) {
                    this.$emit("update:value", this.baseurl + this.yu + res.data.url);
                    this.$emit("input", this.baseurl + this.yu + res.data.url);
                    return;
                }
                this.$emit("update:value", res.data.url);
                this.$emit("input", res.data.url);
                return;
            }
            this.$message.error(res.message);
            //   this.$refs.upload.clearFiles()
            this.fileList = this.fileList.slice(0, 1);
        },
        handlePreview(file) {
            console.log(file);
            //   console.log(file)
            //   window.location.href = file.url
            this.$toUrl(file.url);
        },
        handleExceed(files, fileList) {
            this.$message.warning("请删除文件再上传");
        },
        beforeRemove(file, fileList) {
            return this.$confirm(`确定移除 ${file.name}？`);
        },
        showFile(val) {
            var reg = /(http|https):\/\/[\w.-]+(\/[^\s]*)?/;
            if (!reg.test(val)) {
                return this.baseurl + this.yu + val;
            } else {
                return val;
            }
        },
    },
    computed: {
        action() {
            return {
                action: sessionStorage.getItem("action"),
                type: this.type,
            };
        },
        upLoadUrl() {
            if (this.colUrl) {
                if (isHttp(this.colUrl)) {
                    return this.colUrl;
                }
                return process.env.VUE_APP_BASE_URL + this.colUrl;
            }
            return this.$store.getters.imgUrl;
        },
    },
};
</script>

<style lang="less" scoped>
.waring {
    color: #fda400;
    line-height: 24px;
}
</style>
