<template>
    <div>
        <div class="input-box">
            <el-input v-show="key" v-model="row[rowKey]" size="small" class="id" :autofocus="key" @blur="handleInputBlur(row)" />
        </div>
        <span v-show="!key" @click="showinput(row)"> {{ row[rowKey] }}</span>
        <i v-show="!key" :class="'el-icon-edit'" @click="showinput(row)" />
    </div>
</template>

<script>
export default {
    name: "Remark",
    props: {
        row: {
            type: Object,
        },
        url: {
            type: String,
        },
        rowKey: {
            type: String,
            default: "remark",
        },
        idKey: {
            type: String,
            default: "id",
        },
    },
    data() {
        return {
            key: false,
        };
    },
    methods: {
        async handleInputBlur(row) {
            let req = {...row}
            if (this.idKey != "id") {
                req["id"] = req[this.idKey];
            }
            const { data: res } = await this.$http.post(this.url, req);
            if (res.errorCode == 200) {
                this.$message.success(res.message);
                this.$emit("getlist");
                this.key = false;
            }
        },
        showinput() {
            this.key = true;
            setTimeout(() => {
                $(".id input").focus();
            }, 10);
        },
    },
};
</script>

<style lang="less" scoped></style>
