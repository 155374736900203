<template>
    <div>
        <ul v-if="sort && valArr" style="padding-bottom: 5px;">
            <draggable v-model="valArr" group="people" animation="500">
                <el-tag class="tag" closable v-for="(item, index) in valArr" :key="index" @close="tagClick(index)">
                    <template v-if="type == 4">
                        {{ item.title }}
                    </template>
                    <template v-if="type == 1">
                        {{ item.name }}
                    </template>
                    <template v-if="type == 2">
                        {{ item.realName || item.nickname }}
                    </template>
                </el-tag>
            </draggable>
        </ul>
        <el-select
            v-model="val"
            :multiple="multiple"
            filterable
            remote
            :placeholder="valName"
            :remote-method="remoteMethod"
            :size="size"
            :loading="loading"
            @input="input"
            @focus="focus"
            @change="shows"
            :style="{ width: width }"
            :clearable="clearable"
        >
            <template v-if="type == 1">
                <el-option v-for="item in options" :key="item.id" :label="item.name" :value="item.id + ''" />
            </template>
            <template v-if="userCompany">
                <template v-if="type == 2 || type == 7">
                    <el-option
                        v-for="item in options"
                        :key="item.id"
                        :label="item.realName || item.nickname"
                        :value="item.id"
                    />
                </template>
            </template>
            <template v-else>
                <template v-if="type == 2 || type == 7">
                    <el-option
                        v-for="item in options"
                        :key="item.id"
                        :label="item.realName || item.nickname"
                        :value="item.id"
                    />
                </template>
            </template>
            <template v-if="[3, 13].includes(+type)">
                <el-option v-for="item in options" :key="item.id" :label="item.name" :value="item.id" />
            </template>
            <template v-if="type == 4 || type == 11">
                <el-option v-for="item in options" :key="item.id" :label="item.title" :value="item.id" />
            </template>
            <template v-if="type == 5 || type == 6">
                <el-option v-for="item in options" :key="item.id" :label="item.parent" :value="item.id" />
            </template>
            <template v-if="type == 8">
                <el-option v-for="(item, index) in options" :key="index" :value="item.id" :label="item.name">
                    {{ item.name }}（{{ item.activityName }}）
                </el-option>
            </template>
            <template v-if="type == 9">
                <el-option v-for="(item, index) in options" :key="index" :value="item.id" :label="item.title">
                    {{ item.title }}
                </el-option>
            </template>
            <template v-if="type == 10">
                <el-option v-for="(item, index) in options" :key="index" :value="item.id" :label="item.title">
                </el-option>
            </template>
            <template v-if="type == 12">
                <el-option v-for="(item, index) in options" :key="index" :value="item.id" :label="item.title">
                </el-option>
            </template>
        </el-select>
    </div>
</template>

<script>
import draggable from "vuedraggable";
import _ from "lodash";
export default {
    props: {
        value: {
            type: [String, Number, Array],
            default: "",
        },
        all: {
            type: [Boolean],
            default: false,
        },
        type: {
            type: [String, Number],
            default: "",
        },
        tipName: {
            type: [String, Number],
            default: "",
        },
        multiple: {
            type: [String, Boolean],
            default: false,
        },
        clearable: {
            type: [String, Boolean],
            default: false,
        },
        width: {
            default: "480px",
        },
        typeId: {
            default: 0,
        },
        sort: {
            type: [Boolean],
            default: false,
        },
        userCompany: {
            type: [Boolean],
            default: false,
        },
        size: {
            type: String,
            default: "", //medium/small/mini
        },
        title: {
            type: String,
            default: "",
        },
    },
    data() {
        return {
            val: [],
            options: [],
            lookupTable: [],
            loading: false,
        };
    },
    methods: {
        async remoteMethod(qu) {
            if (qu != "") {
                this.loading = true;
                try {
                    if (this.type == 1) {
                        var { data: res } = await this.$http.get(
                            `/admin/Enterprise/getList?page=1&pageSize=50&name=${qu}&isHide=&type=&business=&serverArea=&createDate=&people=&financing=&top=`
                        );
                    } else if (this.type == 2) {
                        var { data: res } = await this.$http.get(
                            `/admin/User/getList?page=1&pageSize=50&keyword=${qu}&order=&type=&status=&waId=&showCircles=&certType=&business=&ugId=&jobLevel=&regDate=&province=&city=`
                        );
                    } else if (this.type == 3) {
                        var { data: res } = await this.$http.get(
                            `/admin/Rotary/getList?page=1&pageSize=10&keyword=${qu}&activityDate=`
                        );
                    } else if (this.type == 4) {
                        var { data: res } = await this.$http.get(
                            `/admin/Activity/getList?page=1&pageSize=10&eventDate=&publishDate=&title=${qu}&publishStatus=&checkStatus=&acId=&order=&authName=`
                        );
                    } else if (this.type == 5) {
                        var { data: res } = await this.$http.get(
                            `/admin/EnterpriseCategory/getList?keyword=${qu}&page=1&pageSize=100&order=&typeId=0&businessId=`
                        );
                    } else if (this.type == 6) {
                        var { data: res } = await this.$http.get(
                            `/admin/EnterpriseCategory/getList?keyword=${qu}&page=1&pageSize=100&order=&typeId=${this.typeId}&businessId=`
                        );
                    } else if (this.type == 7) {
                        var { data: res } = await this.$http.get(
                            `/admin/Activity/searchSpeakers?page=1&pageSize=50&keyword=${qu}`
                        );
                    } else if (this.type == 8) {
                        var { data: res } = await this.$http.get(
                            `/admin/Activity/searchTicketList?page=1&pageSize=50&keyword=${qu}`
                        );
                    } else if (this.type == 9) {
                        var { data: res } = await this.$http.get(
                            `/admin/Article/getList?query=&page=1&pageSize=10&title=${qu}&checkStatus=&publishStatus=&classification=&bigPicture=&authName=&order=`
                        );
                    } else if (this.type == 10) {
                        var { data: res } = await this.$http.get(
                            `/admin/Cooperation/getList?page=1&pageSize=10&keyword=${qu}&isHide=&type=&area=&field=&createDate=&recommend=`
                        );
                    } else if (this.type == 11) {
                        var { data: res } = await this.$http.get(
                            `/admin/Report/getList?page=1&pageSize=10&date=&title=${qu}&year=&publishStatus=&reportArea=&reportBusiness=&order=&authName=`
                        );
                    } else if (this.type == 12) {
                        var { data: res } = await this.$http.get(
                            `/admin/Sms/getTemplateList?page=1&pageSize=100&keyword=${qu}`
                        );
                    } else if (this.type == 13) {
                        var { data: res } = await this.$http.get(
                            `/admin/Sms/getTagList?page=1&pageSize=100&keyword=${qu}`
                        );
                    }
                    this.loading = false;
                    if (res.errorCode == 200) {
                        this.options = res.data.list;
                        if (this.userCompany) {
                            this.options.forEach((item) => {
                                item.realName = item.realName + this.getCompany(item.company);
                                item.nickname = item.nickname + this.getCompany(item.company);
                            });
                        }
                    }
                } catch (error) {
                    this.loading = false;
                }
            }
            // this.loading = true;
            //     try {
            //         if (this.type == 1) {
            //             var { data: res } = await this.$http.get(`/admin/Enterprise/getList?page=1&pageSize=50&name=${qu}&isHide=&type=&business=&serverArea=&createDate=&people=&financing=&top=`);
            //         } else if (this.type == 2) {
            //             var { data: res } = await this.$http.get(
            //                 `/admin/User/getList?page=1&pageSize=50&keyword=${qu}&order=&type=&status=&waId=&showCircles=&certType=&business=&ugId=&jobLevel=&regDate=&province=&city=`
            //             );
            //         } else if (this.type == 3) {
            //             var { data: res } = await this.$http.get(`/admin/Rotary/getList?page=1&pageSize=10&keyword=${qu}&activityDate=`);
            //         } else if (this.type == 4) {
            //             var { data: res } = await this.$http.get(`/admin/Activity/getList?page=1&pageSize=10&eventDate=&publishDate=&title=${qu}&publishStatus=&checkStatus=&acId=&order=&authName=`);
            //         } else if (this.type == 5) {
            //             var { data: res } = await this.$http.get(`/admin/EnterpriseCategory/getList?keyword=${qu}&page=1&pageSize=100&order=&typeId=0&businessId=`);
            //         } else if (this.type == 6) {
            //             var { data: res } = await this.$http.get(`/admin/EnterpriseCategory/getList?keyword=${qu}&page=1&pageSize=100&order=&typeId=${this.typeId}&businessId=`);
            //         } else if (this.type == 7) {
            //             var { data: res } = await this.$http.get(`/admin/Activity/searchSpeakers?page=1&pageSize=50&keyword=${qu}`);
            //         } else if (this.type == 8) {
            //             var { data: res } = await this.$http.get(`/admin/Activity/searchTicketList?page=1&pageSize=50&keyword=${qu}`);
            //         } else if (this.type == 9) {
            //             var { data: res } = await this.$http.get(
            //                 `/admin/Article/getList?query=&page=1&pageSize=10&title=${qu}&checkStatus=&publishStatus=&classification=&bigPicture=&authName=&order=`
            //             );
            //         } else if (this.type == 10) {
            //             var { data: res } = await this.$http.get(`/admin/Cooperation/getList?page=1&pageSize=10&keyword=${qu}&isHide=&type=&area=&field=&createDate=&recommend=`);
            //         } else if (this.type == 11) {
            //             var { data: res } = await this.$http.get(`/admin/Report/getList?page=1&pageSize=10&date=&title=${qu}&year=&publishStatus=&reportArea=&reportBusiness=&order=&authName=`);
            //         } else if (this.type == 12){
            //             var { data: res } = await this.$http.get(`https://test.yfchuhai.com/admin/Sms/getTemplateList?page=1&pageSize=100&keyword=${qu}`);
            //         }
            //         this.loading = false;
            //         if (res.errorCode == 200) {
            //             this.options = res.data.list;
            //             if (this.userCompany) {
            //                 this.options.forEach((item) => {
            //                     item.realName = item.realName + this.getCompany(item.company);
            //                     item.nickname = item.nickname + this.getCompany(item.company);
            //                 });
            //             }
            //         }
            //     } catch (error) {
            //         this.loading = false;
            //     }
        },
        tagClick(val) {
            this.valArr.splice(val, 1);
            this.mapVal(this.valArr);
        },
        input() {
            this.$emit("input");
        },
        focus() {
            this.$emit("focus");
        },
        getCompany(val) {
            if (!val) return "";
            return `  (${val})`;
        },
        shows(val, val2) {
            if (this.options && (this.type == 9 || this.type == 10 || this.type == 11)) {
                var obj = this.options.find((item) => item.id == val);
                if (obj) {
                    this.$emit("update:title", obj.title);
                }
            }
            this.$emit("update:value", this.val);
        },
        mapVal(val) {
            var key = "id";
            if (this.type == 1) {
                this.val = val.map((item) => {
                    return item[key] + "";
                });
                this.$emit("update:value", this.val);
                return;
            }
            this.val = val.map((item) => {
                return item[key];
            });
            this.$emit("update:value", this.val);
        },
        onClear() {},
    },
    watch: {
        value: {
            handler(val) {
                // if (!val) return;
                this.val = val;
            },
            immediate: true,
            //   console.log(this.val);
        },
        // type: {
        //     handler(val) {
        //         console.log(val);
        //     },
        //     immediate: true,
        // },
        options: {
            deep: true,
            handler(val) {
                if (!val) return;
                this.lookupTable = _.uniqBy([...val, ...this.lookupTable], "id");
            },
        },
    },
    computed: {
        valName() {
            if (this.all) {
                return this.tipName;
            }
            return `请输入${this.tipName}名称模糊查询`;
        },

        valArr: {
            set(val) {
                this.mapVal(val);
            },
            get() {
                if (!this.val) return;
                console.log(this.val);
                return this.val.map((id) => {
                    return this.lookupTable.find((item) => item.id == id);
                });
            },
        },
    },
    mounted() {},
    components: {
        draggable,
    },
};
</script>

<style lang="less" scoped>
.tag {
    margin: 5px;
    cursor: move;
}
</style>
